<template>
  <section
    v-if="battle.permissions.showAgreement || battle.permissions.showWaiver"
    class="agreements"
  >
    <v-checkbox
      v-if="battle.hasAgreement"
      v-model="isAgreementChecked"
      class="mb-6 checkbox"
      color="var(--color-blue-800)"
      hide-details
      readonly
      @click="isShowAgreement = true"
    >
      <template v-slot:label>
        Must agree to the competitor
        <a
          href="#"
          @click.prevent
          >agreement</a
        >
        to register
      </template>
    </v-checkbox>
    <OrganizationAgreement
      :check-date="battle.orgLegalDocs.agreementSignedAt"
      :disabled="isLoadingAgreement"
      :document="battle.orgLegalDocs.agreement"
      :is-checked="isAgreementChecked"
      :is-show-modal.sync="isShowAgreement"
      @sign="signAgreement"
    />

    <v-checkbox
      v-if="battle.hasWaiver"
      v-model="isWaiverChecked"
      class="my-4 checkbox"
      color="var(--color-blue-800)"
      hide-details
      readonly
      @click="isShowWaiver = true"
    >
      <template v-slot:label>
        Must agree to the
        <a
          href="#"
          @click.prevent
          >terms of this waiver</a
        >
        to register
      </template>
    </v-checkbox>

    <OrganizationWaiver
      :check-date="battle.orgLegalDocs.waiverSignedAt"
      :disabled="isLoadingWaiver"
      :document="battle.orgLegalDocs.waiver"
      :is-checked="isWaiverChecked"
      :is-show-modal.sync="isShowWaiver"
      @sign="signWaiver"
    />
  </section>
</template>

<script>
import api from '@/api'
import OrganizationWaiver from '@/components/OrganizationWaiver.vue'
import OrganizationAgreement from '@/components/OrganizationAgreement.vue'

export default {
  name: 'AgreementsPage',
  components: {
    OrganizationWaiver,
    OrganizationAgreement,
  },
  props: {
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowAgreement: false,
      isShowWaiver: false,
      isLoadingWaiver: false,
      isLoadingAgreement: false,
    }
  },
  computed: {
    isWaiverChecked() {
      if (!this.battle.orgLegalDocs.waiverSignedAt) return false
      return new Date(this.battle.orgLegalDocs.waiverSignedAt).getFullYear() > 2000
    },
    isAgreementChecked() {
      if (!this.battle.orgLegalDocs.agreementSignedAt) return false
      return new Date(this.battle.orgLegalDocs.agreementSignedAt).getFullYear() > 2000
    },
  },
  methods: {
    async signAgreement() {
      this.isLoadingAgreement = true
      const updatedBattle = await api.battles.signAgreement(this.battle.id)
      this.isLoadingAgreement = false
      if (!updatedBattle) return

      this.$emit('update:battle', updatedBattle)
    },
    async signWaiver() {
      this.isLoadingWaiver = true
      const updatedBattle = await api.battles.signWaiver(this.battle.id)
      this.isLoadingWaiver = false
      if (!updatedBattle) return

      this.$emit('update:battle', updatedBattle)
    },
  },
}
</script>

<style lang="scss" scoped>
.agreements {
  .checkbox ::v-deep .v-label {
    display: block;
  }
}
</style>
