import { render, staticRenderFns } from "./OrganizationAgreement.vue?vue&type=template&id=37ab3080&scoped=true"
import script from "./OrganizationAgreement.vue?vue&type=script&lang=js"
export * from "./OrganizationAgreement.vue?vue&type=script&lang=js"
import style0 from "./OrganizationAgreement.vue?vue&type=style&index=0&id=37ab3080&prod&lang=scss"
import style1 from "./OrganizationAgreement.vue?vue&type=style&index=1&id=37ab3080&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ab3080",
  null
  
)

export default component.exports