<template>
  <ModalWrapper
    :is-show="isShowModal"
    :modal-width="'880px'"
    content-class="agreement"
    @update:isShow="$emit('update:isShowModal', false)"
  >
    <v-card
      class="agreement__card pa-4 pa-sm-10 pb-4 pb-sm-4"
      outlined
      tile
    >
      <div class="agreement__content">
        <h2
          v-if="document.title"
          class="agreement__title"
        >
          {{ document.title }}
        </h2>
        <h3
          v-if="document.subtitle"
          class="agreement__subtitle"
        >
          {{ document.subtitle }}
        </h3>
        <p
          v-if="document.version"
          class="agreement__version"
        >
          {{ document.version }}
        </p>
        <div class="agreement__body">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p
            class="agreement__body-text"
            v-html="$sanitize(document.text)"
          />
        </div>
      </div>
      <div class="agreement__actions">
        <v-checkbox
          v-model="checked"
          class="my-4"
          color="var(--color-blue-800)"
          hide-details
          readonly
          @click.stop="sign"
        >
          <template v-slot:label>
            <div
              v-if="isChecked"
              class="agreement__label"
            >
              I agree to the competitor agreement
              <p
                v-if="checkDate"
                class="agreement__text-signed"
              >
                Signed on {{ getDateFormatted(checkDate) }}
              </p>
            </div>
            <div
              v-else
              class="agreement__label"
            >
              Agree to the competitor agreement to complete registration
            </div>
          </template>
        </v-checkbox>

        <v-btn
          v-if="isChecked"
          class="white--text"
          color="var(--color-blue-800)"
          @click="$emit('update:isShowModal', false)"
        >
          Continue
        </v-btn>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script>
import { ModalWrapper } from '@/shared/ui/modals'
import { getDateFormatted } from '@/services/date'

export default {
  name: 'OrganizationAgreement',
  components: {
    ModalWrapper,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    checkDate: {
      validator: (value) => value instanceof Date || value === null,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isChecked,
    }
  },
  methods: {
    getDateFormatted,
    sign() {
      if (this.disabled) return
      this.checked = !this.checked
      this.$emit('sign')
    },
  },
}
</script>

<style lang="scss">
.agreement {
  height: 100%;
}

.agreement__body-text {
  p {
    margin: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.agreement {
  &__close {
    position: absolute;
    z-index: 1;
    width: auto;
    margin-top: -30px;
    text-transform: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__title {
    @include font-h3;

    text-transform: uppercase;
  }

  &__subtitle {
    color: var(--color-dark-800);
    font-weight: 700;
  }

  &__version {
    @include font-body2;

    margin: 0;
    color: var(--color-dark-800);
  }

  &__content {
    overflow-y: auto;

    @media (max-width: 600px) {
      padding: 60px 20px 0;
    }
  }

  &__body {
    min-height: 150px;
    padding-right: 15px;
  }

  &__body-text {
    color: var(--color-dark-800);
    font-weight: normal;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #c4c4c4;

    @media (max-width: 600px) {
      padding: 20px;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0;
  }

  &__text-signed {
    @include font-body2;

    margin: 0;
    opacity: 0.6;
  }
}
</style>
